body {
  margin: 0;
  padding: 0;
}

.show {
  display: block;
}
.App-header {
  font-size: 15px;
  background-color: #5b49d2;
  color: white;
  /* padding: 15px; */
  text-align: center;
  z-index: 1 !important;
}

.App-header-p {
  font-size: 15px;
  color: white;
  text-align: center;
  padding: 10px;
}
.App-header-p a {
  text-decoration: none;
  color: inherit;
}
.App-header-p a:hover {
  color: black;
  text-align: center;
}
.App-header-p a:visited {
  color: inherit;
}
.App-header-p a:focus {
  outline: none;
}

.suiteflieet {
  margin-top: 17px;
  background-color: #5b49d2;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: Arial, sans-serif;
}
.suiteflieet :hover {
  background-color: #5b49d28f;
}
