.Footer {
  position: absolute;
  bottom: -34px;
  width: 100%;
  background: #fafafa;
}
.displaying-orders {
  position: absolute;
  right: 282px;
}

.displaying-orders label {
  margin-bottom: 0;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #0d8cd1 !important;
}

@media (max-width: 500px) {
  .Footer {
    flex-flow: column;
    text-align: center;
    align-items: center;
  }
}
