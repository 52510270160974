body {
  margin: 0;
  padding: 0;
}

.show {
  display: block;
}
.App-header {
  font-size: 15px;
  background-color: #5b49d2;
  color: white;
  /* padding: 15px; */
  text-align: center;
  z-index: 1 !important;
}

.App-header-p {
  font-size: 15px;
  color: white;
  text-align: center;
  padding: 10px;
}
.App-header-p a {
  text-decoration: none;
  color: inherit;
}
.App-header-p a:hover {
  color: black;
  text-align: center;
}
.App-header-p a:visited {
  color: inherit;
}
.App-header-p a:focus {
  outline: none;
}

.suiteflieet {
  margin-top: 17px;
  background-color: #5b49d2;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: Arial, sans-serif;
}
.suiteflieet :hover {
  background-color: #5b49d28f;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

body {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  background-color: #f5f5f5;
  overflow: hidden;
}

*:focus {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

a {
  cursor: pointer;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-color: #f5c6cb;
  /* margin: 0 auto; */
  position: absolute;
  right: 26%;
  padding: 40px;
}

.btn-primary {
  background-color: #0d8cd1;
  border: none;
  border-radius: 4px;
  box-shadow: 0 11px 6px -6px #ddd;
  font-weight: 300 !important;
  border-radius: 4px;
}

button.btn.btn-secondary {
  border-radius: 4px;
  border: none;
}

/* ///////////////////////////// SCROLLBAR //////////////////////////// */
*/ ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
/* //////////////////////////////////// TYPOGRAPHY //////////////////////////////////// */

h1,
h2,
h3 {
  font-weight: 300;
}

@media only screen and (max-width: 1400px) {
  html {
    font-size: 0.9375rem;
  }
}

/* //////////////////////////////////// ANIMATIONS //////////////////////////////////// */
.fade-in {
  animation-name: fadeFromTop;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

.fade-in-2 {
  animation-name: fadeFromTop2;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
}

@keyframes fadeFromTop {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeFromTop2 {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 720px) {
  .mobile-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/* //////////////////////////////////// PRINT CLASSES //////////////////////////////////// */
.tcorp-print-element {
  display: none;
}

.print-selection {
  width: 60vw;
  background: #fff;
  padding: 5px;
}
.hide {
  display: none;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    width: 21cm;
    height: 29.7cm;
    /* margin: 30mm; */
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    /* margin-top: 1rem; */
    display: block;
    page-break-before: auto;
    width: 100vw;
    height: 100vh;
  }
}

@page {
  size: A4;
  /* margin: 20mm; */
  width: 100vw;
  height: 100vh;
}

/* @media only print {
  .tcorp-print-element {
    margin-top: 1rem;
    display: block;
  }

  .tcorp-print-hide {
    display: none;
  }

  .tcorp-table-container {
    margin-top: 2rem;
    overflow: scroll;
    height: 70vh;
  }
} */

/* //////////////////////////////////// NAVBAR ////////////////////////////////////*/
.navbar-dark {
  /* border-bottom: 2px solid #dee2e6; */
  background: #fff;
  box-shadow: 0 8px 6px -6px #ddd;
  padding: 15px 20px 12px;
}

.navbar-brand img {
  width: 187px;
}

.navbar .nav-link {
  color: #616161 !important;
}
.orders-nav.navbar {
  padding: 0;
}

.nav-link.active,
.show > .nav-link {
  color: #0d8cd1 !important;
  border: none;
}

.text-primary {
  color: #0d8cd1 !important;
}

.btn-outline-primary {
  color: #0d8cd1;
  background-color: transparent;
  border: none;
  background-image: none;
  box-shadow: 0px 3px 5px 2px rgba(221, 221, 221, 1) !important;
}

.table thead th {
  border: none;
  font-weight: 300;
}

.table td,
.table th {
  padding: 0.75rem;
  font-size: 14px;
  color: #665656;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  border-top: 1px solid #dee2e6;
}

.tcorp-table .thead {
  background: #f6f6f6;
}

.badge {
  display: inline-block;
  padding: 0.45em 0.4em;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  /* border-radius: 7px; */
  width: 125px;
}

.badge-secondary {
  color: #fff;
  background-color: #4acd62;
}

.badge-success {
  color: #fff;
  background-color: #09d3ef;
}

.badge-primary {
  color: #fff;
  background-color: #cae25f;
}

.badge-inTransit {
  color: #fff;
  background-color: #3f8743;
}

.badge-warning {
  color: #fff;
  background-color: #357bae;
}

.calendar-nav-btns button {
  height: 36px;
}
.calendar-nav-btns button.MuiFab-sizeSmall {
  width: 36px;
}

.input-group select,
.input-group input {
  border: none;
  border-radius: 4px;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 8px 6px -6px #ddd;
}

.modal-body .form-group p {
  background: #fff;
  border-radius: 4px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 35px;
  font-size: 15px;
  padding-top: 7px;
}

.modal-body form .col-lg-4 {
  max-width: 33.33%;
}
.modal-body form .col-lg-3,
.modal-body form .col-lg-4,
.modal-body form .col-lg-6,
.modal-body form .col-lg-9,
.modal-body form .col-12 {
  padding-right: 5px;
  padding-left: 5px;
}

.modal-body form .col-lg-3 p,
.modal-body form .col-lg-4 p,
.modal-body form .col-lg-6 p,
.modal-body form .col-12 p,
.modal-body form .col-lg-3 small,
.modal-body form .col-lg-4 small,
.modal-body form .col-lg-6 small,
.modal-body form .col-12 small {
  padding-left: 6px;
}

.modal form .form-control {
  border: 1px solid #f2f2f2;
  border-radius: none;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 5px 6px -6px #ddd;
}

.modal form .form-control:focus,
.modal form .form-control:hover {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0d8cd1;
  border-color: #0d8cd1;
}

.modal {
  z-index: 6;
}

.modal-body {
  font-size: 14px;
  background-color: #f9f9f9;
}

.modal-header {
  border: solid 1px #eee;
}

.modal-body h5 {
  font-size: 18px;
}

.modal-content {
  border: none;
  box-shadow: 3px 13px 44px -8px rgba(153, 153, 153, 1);
  z-index: 1900;
  max-height: 90vh;
  overflow: auto;
}

.modal-backdrop {
  position: relative;
}

.container-fluid .graph-container {
  max-width: 76%;
}

.graph-container .card-body {
  height: 300px;
}

.complete-status {
  width: 80%;
}

.chart-div {
  width: 260px;
  height: 200px;
  padding-top: 9px;
}

.graph-container col-lg-3 {
  padding-right: 0px;
}

a.nav-item.nav-link.text-nowrap.active {
  border-bottom: 3px solid #0d8cd1;
}

.MuiTableCell-head {
  font-weight: 300 !important;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 12px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300 !important;
  line-height: 1.43;
  border-bottom: 1px solid #eaeaea;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.EnhancedTable-root-3 {
  margin-top: 0 !important;
}

.tcorp-user-table tbody td {
  padding: 13px 5px 0px 16px;
}

.table-responsive {
  overflow-x: auto;
  background: #fff;
  height: 81vh;
}

.tcorp-table-container table thead tr {
  display: flex;
  padding: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 6;
}

/* .navbar .form-control {
  width: 20% !important;
} */

/* //////////////////////////////////// TABLE ////////////////////////////////////*/
@media only screen and (max-width: 1500px) {
  .tcorp-table {
    font-size: 0.9375rem;
  }
}

.red-border {
  border: 1px solid #dc3545;
  border-radius: 4px;
}

.red-text {
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 80%;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 30.333333%;
  }
}

/* ////////////////////////////////////////// FROM APPLICATION HEADER CSS NEED TO INTEGRATE ////////////////////////////// */

/* //////////////////////////////////// HEADER ////////////////////////////////////*/
#flip {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

#flip-label {
  position: relative;
}

.tcorp-header {
  /* background-image: linear-gradient(
    to top,
    rgba(0, 123, 255, 0.12),
    #ffffff 50%
  );
  border-top: 1px solid #ebebeb; */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.tcorp-header-info {
  display: flex;
}
@media (max-width: 1200px) {
  .tcorp-header-info {
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .tcorp-header-info {
    flex-flow: row;
    justify-content: center;
    text-align: left;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1rem;
  }
}
@media (max-width: 991px) {
  .tcorp-header-info {
    text-align: center;
  }
}

.tcorp-header-logo {
  width: 6rem;
  height: auto;
  margin-right: 2rem;
  margin-left: 1rem;
}
@media (max-width: 1200px) {
  .tcorp-header-logo {
    width: 5rem;
  }
}
@media (max-width: 500px) {
  .tcorp-header-logo {
    position: absolute;
    width: 70%;
    height: auto;
    top: 10rem;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 0;
    opacity: 0.1;
  }
}

.tcorp-header-text {
  max-width: 18%;
  z-index: 5;
}
@media (max-width: 1200px) {
  .tcorp-header-text {
    max-width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.tcorp-header-name {
  border-bottom: 1px solid #0d8cd1;
  margin-bottom: 0.5rem;
}

.tcorp-header-back {
  /* background-image: linear-gradient(
    to top left,
    rgba(50, 149, 255, 0.4),
    rgba(50, 149, 255, 0.9) 60%
  );
  border-bottom: 1px solid #ebebeb; */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.tcorp-header-back-fix {
  width: 100%;
  height: 100%;
}

.tcorp-header-back-fix .mobile-margin {
  border-top: 1px solid #ddd;
}

/*- Card container -*/
.tcorp-card-container {
  position: relative;
  z-index: 1;
  height: 8.5rem;
  perspective: 3000px;
}
@media (max-width: 1700px) {
  .tcorp-card-container {
    height: 9rem;
  }
}
@media (max-width: 1400px) {
  .tcorp-card-container {
    height: 11.5rem;
  }
}
@media (max-width: 1200px) {
  .tcorp-card-container {
    height: 14rem;
  }
}
@media (max-width: 991px) {
  .tcorp-card-container {
    height: 20rem;
  }
}
@media (max-width: 575px) {
  .tcorp-card-container {
    height: 30rem;
  }
}
@media (max-width: 550px) {
  .tcorp-card-container {
    height: 32rem;
  }
}

/*- Card body -*/
.tcorp-card-body {
  width: 100%;
  height: 85vh;
  transform-style: preserve-3d;
  transition: all 0.25s ease-in-out;
}

/*- Flipping the card -*/
#flip:checked ~ .tcorp-card-container > .tcorp-card-body {
  transform: rotateY(180deg);
}
@media (max-width: 575px) {
  #flip:checked ~ .tcorp-card-container > .tcorp-card-body {
    transform: rotateY(180deg);
  }
}

#flip:checked ~ .tcorp-card-container > .tcorp-card-body > .side-front {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in, visibility 0.75s ease-in-out;
}

/*- Card sides -*/
.tcorp-card-side {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  color: #212121;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 10px 35px rgba(50, 50, 93, 0.1), 0 2px 15px rgba(0, 0, 0, 0.07);
}

/*- Front side -*/
.side-front {
  top: 1px;
  border: none;
}

.card {
  background-color: #f5f5f5 !important;
  border: none;
}

.tcorp-header-text .graph-container {
  max-width: 82% !important;
  height: 79vh;
  overflow: scroll;
}

.graph-container .form-control,
.graph-container .card-body {
  background-color: #fff;
  border: none;
  box-shadow: 0 8px 6px -6px #ddd;
}

/*- Back side -*/
.side-back {
  z-index: 2;
  transform: rotateY(180deg);
  height: 85vh;
}

@media (max-width: 575px) {
  .side-back {
    transform: rotateY(180deg);
  }
}

/* Activity */
.activity {
  min-height: 85vh;
}
.activity .list-group {
  max-height: 80%;
  overflow: auto;
}

.activity .list-group-item {
  background: white;
  margin: 8px 14px;
  border-radius: 5px;
  padding: 17px;
}

.activity .unassigned {
  border-left: solid 3px #fc9205;
}

.unassigned-heading {
  border-top: solid #fc9205;
}

.activity .assigned {
  border-left: solid 3px #0db3c7;
}

.assigned-heading {
  border-top: solid #0db3c7;
}

.activity .inTransit {
  border-left: solid 3px #982fb0;
}

.inTransit-heading {
  border-top: solid #982fb0;
}

.activity .complete {
  border-top: solid 3px #4da751;
}

.complete-heading {
  border-top: solid#4DA751;
}

.activity .failed {
  border-left: solid 3px #e8403d;
}

.failed-heading {
  border-top: solid #e8403d;
}

.activity h5 {
  padding: 7px 10px 10px 10px;
}

.empty-activity {
  height: 68px;
  padding: 18px 10px;
}

.calendar-container {
  overflow: auto;
  height: 80vh;
}

/* filter inputs */
.filter-inputs .MuiFormControl-root.MuiTextField-root {
  margin: 8px 0;
  width: 100%;
}

.filter-inputs .MuiButtonBase-root.MuiButton-root {
  margin: 5px 0;
}

.MuiSelect-select.MuiSelect-select {
  min-width: 82px;
}

.fade-in.container-fluid.main-container {
  background: #f9f9f9;
}

#filter-btn {
  min-width: 120px;
}

#orderTableNavbar .MuiTabs-root {
  width: 100%;
}

.filter-container .MuiFormControl-root {
  height: 41px;
  margin-right: 2px;
  margin-top: 1px;
  width: 230px;
}

.disabled {
  color: red !important;
}

.filter-container
  .MuiInputBase-root.MuiInput-root.searchCategory.MuiInputBase-formControl.MuiInput-formControl,
.filter-container
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
  height: 36px;
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: 1px 2px 5px 0px rgba(221, 221, 221, 1);
  padding: 0 10px;
  /* font-size: 14px; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 5px;
  color: #777;
}

.filter-container .MuiInput-underline:before {
  border-bottom: none;
}

.MuiBadge-colorPrimary {
  color: #fff;
  background-color: #0d8cd1 !important;
}

.EnhancedTable-root-8 {
  margin-top: 0;
}

.MuiAppBar-root a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:focus,
.MuiAppBar-root span.MuiTab-wrapper:focus,
.MuiAppBar-root a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:active,
.MuiAppBar-root span.MuiTab-wrapper:active,
.MuiAppBar-root a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:hover,
.MuiAppBar-root span.MuiTab-wrapper:hover {
  transition: 0.4s;
  text-decoration: none;
  opacity: 1;
}

.EnhancedTable-root-11 {
  margin-top: 0px !important;
}

.upload-container {
  max-height: 90vh;
  min-height: 81vh;
  width: 100%;
  overflow: auto;
}

#filters_body {
  width: 100%;
}

#filters_body .drawer-filter {
  width: 100px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: none;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #777777;
}

input#nav_elm::placeholder {
  color: #777;
  opacity: 1;
}

.rs-picker-toolbar-option {
  color: #0d8cd1 !important;
}

.rs-picker-toolbar-right-btn-ok {
  background-color: #0d8cd1 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  border-color: #0d8cd1 !important;
  background-color: #0d8cd1 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.rs-picker-toggle-placeholder {
  color: #777 !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.rs-picker-default .rs-picker-toggle {
  border: 1px solid rgb(221, 221, 221) !important;
}

.rs-picker-menu {
  z-index: 10 !important;
}

.filter-nav {
  display: flex;
  align-items: flex-start;
}

.makeStyles-toolbar-2 {
  z-index: 9999;
}

.fa-file-download {
  font-size: 19px;
}

.active-order-tabs {
  position: absolute;
  right: 0;
  box-shadow: 1px 2px 5px 0px rgba(221, 221, 221, 1);
}

.MuiTypography-h6 {
  font-size: 1rem !important;
}

.EnhancedTable-tableWrapper-40 {
  overflow: hidden !important;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
  padding: 4px 0px 4px 4px !important;
}

.blue-text {
  color: "#0d8cd1";
}
/* csv errors */
.csv-error-table {
  background: white;
  width: 99%;
  height: 92%;
  margin: 0 auto;
  padding: 14px;
  overflow: auto;
}
.csv-error-table th,
.csv-error-table td {
  border: solid 1px #ddd;
  padding: 5px;
}
.csv-error-fieldset {
  padding: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
}
.csv-error-input {
  border: none;
}
.csv-error {
  border: solid red 1px;
  color: red;
}
.csv-error-text {
  font-size: 10px;
  color: red;
  margin-bottom: -4px;
}

.ct-legend {
  position: relative;
  z-index: 10;
  display: flex;
}
.ct-legend li {
  position: relative;
  padding-left: 23px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.ct-legend li:before {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  content: "";
  border: 3px solid transparent;
  border-radius: 2px;
}
.ct-legend .inactive:before {
  background: transparent;
}
.ct-legend.ct-legend-inside {
  position: absolute;
  top: 0;
  right: 0;
}

.complete-percentage {
  background: #3fb259;
  border-radius: 3px;
  padding: 5px 15px;
  height: 32px;
  color: #fff;
}

.breakdown-report-filter {
  background: #ee5d59;
  border-radius: 3px;
  padding: 17px 15px;
  height: 46px;
}

input[type="file"] {
  display: none;
}

/* freshchat : position of the web messenger */

.custom_fc_frame {
  left: 20px !important;
  bottom: 30px !important;
}
#launcher-frame {
  bottom: 30px !important;
}

/*!

 =========================================================
 * Material Dashboard  React- v1.9.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

/* ANIMATION */

/* SHADOWS */

/* Shadows (from mdl http://www.getmdl.io/) */

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255);
  font-size: 0.7rem;
  line-height: 1;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex;
}

.ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  margin-top: 27px;
  margin-left: -21px;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  width: 90px !important;
}

svg:not(:root) {
  overflow: visible !important;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-point {
  stroke-width: 8px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 3px;
}

.ct-area {
  stroke: none;
  fill-opacity: 0.8;
}

.ct-bar {
  fill: none;
  stroke-width: 10px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}

.ct-series-0,
.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #fff;
}

.ct-legend .ct-series-0:before {
  background-color: #fff;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #fff;
}

.ct-series-1,
.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #c7f8fc;
}

.ct-legend .ct-series-1:before {
  background-color: #c7f8fc;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #c7f8fc;
}

.ct-series-2,
.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #08dcf3;
}

.ct-legend .ct-series-2:before {
  background-color: #08dcf3;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #08dcf3;
}

.ct-series-3,
.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #2b75ab;
}

.ct-legend .ct-series-3:before {
  background-color: #2b75ab;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #2b75ab;
}

.ct-series-4,
.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #c2de59;
}

.ct-legend .ct-series-4:before {
  background-color: #c2de59;
}

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
  fill: #c2de59;
}

.ct-series-5,
.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #f44336;
}

.ct-legend .ct-series-5:before {
  background-color: #f44336;
}

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
  fill: #f44336;
}

.ct-series-6,
.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #a535a6;
}

.ct-legend .ct-series-6:before {
  background-color: #a535a6;
}

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-area {
  fill: #a535a6;
}

.ct-series-7,
.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
  stroke: #dd4b39;
}

.ct-legend .ct-series-7:before {
  background-color: #dd4b39;
}

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-area {
  fill: #dd4b39;
}

.ct-legend .ct-series-8:before {
  background-color: #35465c;
}

.ct-series-8,
.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
  stroke: #35465c;
}

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-area {
  fill: #35465c;
}

.ct-series-9,
.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
  stroke: #e52d27;
}

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-area {
  fill: #e52d27;
}

.ct-series-10,
.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
  stroke: #55acee;
}

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-area {
  fill: #55acee;
}

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
  stroke: #cc2127;
}

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-area {
  fill: #cc2127;
}

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
  stroke: #1769ff;
}

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-area {
  fill: #1769ff;
}

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2;
}

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-area {
  fill: #6188e2;
}

.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca;
}

.ct-series-o .ct-slice-pie,
.ct-series-o .ct-area {
  fill: #a748ca;
}

.ct-legend li {
  position: relative;
  padding-left: 23px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  display: inline-block;
}

.ct-square {
  display: block;
  position: relative;
  width: 100%;
}

.ct-square:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 100%;
}

.ct-square:after {
  content: '';
  display: table;
  clear: both;
}

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}

.ct-minor-second:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 88.88889%;
}

.ct-major-second:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 83.33333%;
}

.ct-minor-third:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 80%;
}

.ct-major-third:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 75%;
}

.ct-perfect-fourth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 66.66667%;
}

.ct-perfect-fifth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}

.ct-minor-sixth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}

.ct-golden-section:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 61.8047%;
}

.ct-golden-section:after {
  content: '';
  display: table;
  clear: both;
}

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 60%;
}

.ct-major-sixth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}

.ct-minor-seventh:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 53.33333%;
}

.ct-major-seventh:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 50%;
}

.ct-octave:after {
  content: '';
  display: table;
  clear: both;
}

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-tenth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 40%;
}

.ct-major-tenth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}

.ct-major-eleventh:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 33.33333%;
}

.ct-major-twelfth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-double-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 25%;
}

.ct-double-octave:after {
  content: '';
  display: table;
  clear: both;
}

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-blue {
  stroke: #9c27b0 !important;
}

.ct-azure {
  stroke: #00bcd4 !important;
}

.ct-green {
  stroke: #4caf50 !important;
}

.ct-orange {
  stroke: #ff9800 !important;
}

.ct-red {
  stroke: #f44336 !important;
}

.ct-white {
  stroke: #ffffff !important;
}

.ct-rose {
  stroke: #e91e63 !important;
}

.Footer {
  position: absolute;
  bottom: -34px;
  width: 100%;
  background: #fafafa;
}
.displaying-orders {
  position: absolute;
  right: 282px;
}

.displaying-orders label {
  margin-bottom: 0;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #0d8cd1 !important;
}

@media (max-width: 500px) {
  .Footer {
    flex-flow: column;
    text-align: center;
    align-items: center;
  }
}

.Logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.Logo-img {
  height: auto;
  width: auto;
  max-width: 17rem;
  max-height: 20rem;
}

@media only screen and (max-width: 576px) {
  .Logo-img {
    display: none;
  }
}

@media only screen and (max-width: 992px) and (max-height: 990px) {
  .Logo-img {
    display: none;
  }
}

.fade-in-2.container-fluid.mobile-margin {
  height: 93vh;
  background: #f7f7f7;
  /* overflow: auto; */
}

.Login {
  padding-top: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url(../../static/media/dubai-skyline.7122474f.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Login .col-md-4 {
  background: #fff;
  border-radius: 10px;
  padding: 50px 30px;
  border: 1px solid #aeaeae;
  margin-bottom: 60px;
}

/* 
.orders-nav-2 {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #DEE2E6;
} */
.mb-2 {
  margin-bottom: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.h0 > div {
  height: unset !important;
}

.pointer {
  cursor: pointer;
}

input#react-csv-reader-input {
  display: none;
}

.csv-upload-btn {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.875rem;
  height: 36px;
  padding: 8px 16px 5px;
}

.csv-upload-btn:hover {
  cursor: pointer;
  background-color: #0069d9;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 8px !important;
  padding-bottom: 7px !important;
  box-shadow: 1px 1px 5px 0px rgba(221, 221, 221, 1);
  border-radius: 4px;
}

.EnhancedTable-root-1,
.EnhancedTable-root-19 {
  margin-top: 0 !important;
}

.MuiTableSortLabel {
  opacity: 0.3;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 245, 87, 0.04) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #00f557 !important;
}

.EnhancedTableToolbar-highlight-15 {
  color: #00f557 !important;
  background-color: rgb(226, 255, 236) !important;
}

.print-container-scroll {
  height: 100vh;
  overflow-y: auto;
}

.tcorp-print-container {
  margin: 4px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #fff;
  padding: 1px;
}

.print-nav {
  margin: 12px 0 0;
  display: flex;
  justify-content: space-between;
}

.label-div {
  width: 100%;
  margin: 0;
  height: 42px;
  padding-left: 4px;
  display: flex;
  flex-direction: row;
}

.label-div small {
  display: inline-block;
  width: 54px;
}

.label-card {
  max-width: 100%;
  min-width: 100%;
  height: 300px;
}

.label-div span {
  color: black;
  text-align: center;
  font-weight: bold;
}
@media (max-width: 700px) {
  .tcorp-print-container {
    margin: 20px;
    width: 100%;
  }
}

div#packageImage,
div#sigImage {
  width: 100%;
  background: #fff;
}

#packageImage img,
#sigImage img {
  height: 340px;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  background: #fff;
}

.sms-container {
  background: #fff;
  width: 187px;
  height: 38px;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  padding-left: 16px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 5px 6px -6px #ddd;
}

.rbc-month-view {
  background-color: #fff;
}

#calendarNavbar .btn {
  background-color: #fff;
  color: #616161;
}

.rbc-day-bg.rbc-off-range-bg {
  background-color: #fafafa;
}

.rbc-row .unassigned,
.rbc-overlay .unassigned {
  background-color: #cae25f;
}

.rbc-row .assigned,
.rbc-overlay .assigned {
  background-color: #4acd62;
}

.rbc-row .inTransit,
.rbc-overlay .inTransit {
  background-color: #3f8743;
}

.rbc-row .complete,
.rbc-overlay .complete {
  background-color: #09d3ef;
}

.rbc-row .failed,
rbc-overlay .failed {
  background-color: red;
}

@media only screen and (max-width: 400px) {
  .Calendar {
    font-size: 0.875rem;
  }
}

