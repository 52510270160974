.rbc-month-view {
  background-color: #fff;
}

#calendarNavbar .btn {
  background-color: #fff;
  color: #616161;
}

.rbc-day-bg.rbc-off-range-bg {
  background-color: #fafafa;
}

.rbc-row .unassigned,
.rbc-overlay .unassigned {
  background-color: #cae25f;
}

.rbc-row .assigned,
.rbc-overlay .assigned {
  background-color: #4acd62;
}

.rbc-row .inTransit,
.rbc-overlay .inTransit {
  background-color: #3f8743;
}

.rbc-row .complete,
.rbc-overlay .complete {
  background-color: #09d3ef;
}

.rbc-row .failed,
rbc-overlay .failed {
  background-color: red;
}

@media only screen and (max-width: 400px) {
  .Calendar {
    font-size: 0.875rem;
  }
}
