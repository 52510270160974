.App {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

body {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  background-color: #f5f5f5;
  overflow: hidden;
}

*:focus {
  outline: none !important;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

a {
  cursor: pointer;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  max-width: fit-content;
  border-color: #f5c6cb;
  /* margin: 0 auto; */
  position: absolute;
  right: 26%;
  padding: 40px;
}

.btn-primary {
  background-color: #0d8cd1;
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0 8px 6px -6px #ddd;
  box-shadow: 0 11px 6px -6px #ddd;
  font-weight: 300 !important;
  border-radius: 4px;
}

button.btn.btn-secondary {
  border-radius: 4px;
  border: none;
}

/* ///////////////////////////// SCROLLBAR //////////////////////////// */
*/ ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
/* //////////////////////////////////// TYPOGRAPHY //////////////////////////////////// */

h1,
h2,
h3 {
  font-weight: 300;
}

@media only screen and (max-width: 1400px) {
  html {
    font-size: 0.9375rem;
  }
}

/* //////////////////////////////////// ANIMATIONS //////////////////////////////////// */
.fade-in {
  animation-name: fadeFromTop;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

.fade-in-2 {
  animation-name: fadeFromTop2;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
}

@keyframes fadeFromTop {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeFromTop2 {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 720px) {
  .mobile-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/* //////////////////////////////////// PRINT CLASSES //////////////////////////////////// */
.tcorp-print-element {
  display: none;
}

.print-selection {
  width: 60vw;
  background: #fff;
  padding: 5px;
}
.hide {
  display: none;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    width: 21cm;
    height: 29.7cm;
    /* margin: 30mm; */
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    /* margin-top: 1rem; */
    display: block;
    page-break-before: auto;
    width: 100vw;
    height: 100vh;
  }
}

@page {
  size: A4;
  /* margin: 20mm; */
  width: 100vw;
  height: 100vh;
}

/* @media only print {
  .tcorp-print-element {
    margin-top: 1rem;
    display: block;
  }

  .tcorp-print-hide {
    display: none;
  }

  .tcorp-table-container {
    margin-top: 2rem;
    overflow: scroll;
    height: 70vh;
  }
} */

/* //////////////////////////////////// NAVBAR ////////////////////////////////////*/
.navbar-dark {
  /* border-bottom: 2px solid #dee2e6; */
  background: #fff;
  -webkit-box-shadow: 0 8px 6px -6px #ddd;
  -moz-box-shadow: 0 8px 6px -6px #ddd;
  box-shadow: 0 8px 6px -6px #ddd;
  padding: 15px 20px 12px;
}

.navbar-brand img {
  width: 187px;
}

.navbar .nav-link {
  color: #616161 !important;
}
.orders-nav.navbar {
  padding: 0;
}

.nav-link.active,
.show > .nav-link {
  color: #0d8cd1 !important;
  border: none;
}

.text-primary {
  color: #0d8cd1 !important;
}

.btn-outline-primary {
  color: #0d8cd1;
  background-color: transparent;
  border: none;
  background-image: none;
  -webkit-box-shadow: 0px 3px 5px 2px rgba(221, 221, 221, 1);
  -moz-box-shadow: 0px 3px 5px 2px rgba(221, 221, 221, 1);
  box-shadow: 0px 3px 5px 2px rgba(221, 221, 221, 1) !important;
}

.table thead th {
  border: none;
  font-weight: 300;
}

.table td,
.table th {
  padding: 0.75rem;
  font-size: 14px;
  color: #665656;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 150px;
  border-top: 1px solid #dee2e6;
}

.tcorp-table .thead {
  background: #f6f6f6;
}

.badge {
  display: inline-block;
  padding: 0.45em 0.4em;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  /* border-radius: 7px; */
  width: 125px;
}

.badge-secondary {
  color: #fff;
  background-color: #4acd62;
}

.badge-success {
  color: #fff;
  background-color: #09d3ef;
}

.badge-primary {
  color: #fff;
  background-color: #cae25f;
}

.badge-inTransit {
  color: #fff;
  background-color: #3f8743;
}

.badge-warning {
  color: #fff;
  background-color: #357bae;
}

.calendar-nav-btns button {
  height: 36px;
}
.calendar-nav-btns button.MuiFab-sizeSmall {
  width: 36px;
}

.input-group select,
.input-group input {
  border: none;
  border-radius: 4px;
  border-bottom: 1px solid #f0f0f0;
  -webkit-box-shadow: 0 8px 6px -6px #ddd;
  box-shadow: 0 8px 6px -6px #ddd;
}

.modal-body .form-group p {
  background: #fff;
  border-radius: 4px;
  height: fit-content;
  min-height: 35px;
  font-size: 15px;
  padding-top: 7px;
}

.modal-body form .col-lg-4 {
  max-width: 33.33%;
}
.modal-body form .col-lg-3,
.modal-body form .col-lg-4,
.modal-body form .col-lg-6,
.modal-body form .col-lg-9,
.modal-body form .col-12 {
  padding-right: 5px;
  padding-left: 5px;
}

.modal-body form .col-lg-3 p,
.modal-body form .col-lg-4 p,
.modal-body form .col-lg-6 p,
.modal-body form .col-12 p,
.modal-body form .col-lg-3 small,
.modal-body form .col-lg-4 small,
.modal-body form .col-lg-6 small,
.modal-body form .col-12 small {
  padding-left: 6px;
}

.modal form .form-control {
  border: 1px solid #f2f2f2;
  border-radius: none;
  border-bottom: 1px solid #f0f0f0;
  -webkit-box-shadow: 0 8px 6px -6px #ddd;
  box-shadow: 0 5px 6px -6px #ddd;
}

.modal form .form-control:focus,
.modal form .form-control:hover {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0d8cd1;
  border-color: #0d8cd1;
}

.modal {
  z-index: 6;
}

.modal-body {
  font-size: 14px;
  background-color: #f9f9f9;
}

.modal-header {
  border: solid 1px #eee;
}

.modal-body h5 {
  font-size: 18px;
}

.modal-content {
  border: none;
  -webkit-box-shadow: 3px 13px 44px -8px rgba(153, 153, 153, 1);
  -moz-box-shadow: 3px 13px 44px -8px rgba(153, 153, 153, 1);
  box-shadow: 3px 13px 44px -8px rgba(153, 153, 153, 1);
  z-index: 1900;
  max-height: 90vh;
  overflow: auto;
}

.modal-backdrop {
  position: relative;
}

.container-fluid .graph-container {
  max-width: 76%;
}

.graph-container .card-body {
  height: 300px;
}

.complete-status {
  width: 80%;
}

.chart-div {
  width: 260px;
  height: 200px;
  padding-top: 9px;
}

.graph-container col-lg-3 {
  padding-right: 0px;
}

a.nav-item.nav-link.text-nowrap.active {
  border-bottom: 3px solid #0d8cd1;
}

.MuiTableCell-head {
  font-weight: 300 !important;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 12px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300 !important;
  line-height: 1.43;
  border-bottom: 1px solid #eaeaea;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.EnhancedTable-root-3 {
  margin-top: 0 !important;
}

.tcorp-user-table tbody td {
  padding: 13px 5px 0px 16px;
}

.table-responsive {
  overflow-x: auto;
  background: #fff;
  height: 81vh;
}

.tcorp-table-container table thead tr {
  display: flex;
  padding: 0px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 6;
}

/* .navbar .form-control {
  width: 20% !important;
} */

/* //////////////////////////////////// TABLE ////////////////////////////////////*/
@media only screen and (max-width: 1500px) {
  .tcorp-table {
    font-size: 0.9375rem;
  }
}

.red-border {
  border: 1px solid #dc3545;
  border-radius: 4px;
}

.red-text {
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 80%;
}

@media (min-width: 992px) {
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 30.333333%;
  }
}

/* ////////////////////////////////////////// FROM APPLICATION HEADER CSS NEED TO INTEGRATE ////////////////////////////// */

/* //////////////////////////////////// HEADER ////////////////////////////////////*/
#flip {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

#flip-label {
  position: relative;
}

.tcorp-header {
  /* background-image: linear-gradient(
    to top,
    rgba(0, 123, 255, 0.12),
    #ffffff 50%
  );
  border-top: 1px solid #ebebeb; */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.tcorp-header-info {
  display: flex;
}
@media (max-width: 1200px) {
  .tcorp-header-info {
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .tcorp-header-info {
    flex-flow: row;
    justify-content: center;
    text-align: left;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1rem;
  }
}
@media (max-width: 991px) {
  .tcorp-header-info {
    text-align: center;
  }
}

.tcorp-header-logo {
  width: 6rem;
  height: auto;
  margin-right: 2rem;
  margin-left: 1rem;
}
@media (max-width: 1200px) {
  .tcorp-header-logo {
    width: 5rem;
  }
}
@media (max-width: 500px) {
  .tcorp-header-logo {
    position: absolute;
    width: 70%;
    height: auto;
    top: 10rem;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 0;
    opacity: 0.1;
  }
}

.tcorp-header-text {
  max-width: 18%;
  z-index: 5;
}
@media (max-width: 1200px) {
  .tcorp-header-text {
    max-width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.tcorp-header-name {
  border-bottom: 1px solid #0d8cd1;
  margin-bottom: 0.5rem;
}

.tcorp-header-back {
  /* background-image: linear-gradient(
    to top left,
    rgba(50, 149, 255, 0.4),
    rgba(50, 149, 255, 0.9) 60%
  );
  border-bottom: 1px solid #ebebeb; */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.tcorp-header-back-fix {
  width: 100%;
  height: 100%;
}

.tcorp-header-back-fix .mobile-margin {
  border-top: 1px solid #ddd;
}

/*- Card container -*/
.tcorp-card-container {
  position: relative;
  z-index: 1;
  height: 8.5rem;
  -webkit-perspective: 3000px;
  perspective: 3000px;
}
@media (max-width: 1700px) {
  .tcorp-card-container {
    height: 9rem;
  }
}
@media (max-width: 1400px) {
  .tcorp-card-container {
    height: 11.5rem;
  }
}
@media (max-width: 1200px) {
  .tcorp-card-container {
    height: 14rem;
  }
}
@media (max-width: 991px) {
  .tcorp-card-container {
    height: 20rem;
  }
}
@media (max-width: 575px) {
  .tcorp-card-container {
    height: 30rem;
  }
}
@media (max-width: 550px) {
  .tcorp-card-container {
    height: 32rem;
  }
}

/*- Card body -*/
.tcorp-card-body {
  width: 100%;
  height: 85vh;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.25s ease-in-out;
}

/*- Flipping the card -*/
#flip:checked ~ .tcorp-card-container > .tcorp-card-body {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 575px) {
  #flip:checked ~ .tcorp-card-container > .tcorp-card-body {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

#flip:checked ~ .tcorp-card-container > .tcorp-card-body > .side-front {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in, visibility 0.75s ease-in-out;
}

/*- Card sides -*/
.tcorp-card-side {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  color: #212121;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 10px 35px rgba(50, 50, 93, 0.1), 0 2px 15px rgba(0, 0, 0, 0.07);
}

/*- Front side -*/
.side-front {
  top: 1px;
  border: none;
}

.card {
  background-color: #f5f5f5 !important;
  border: none;
}

.tcorp-header-text .graph-container {
  max-width: 82% !important;
  height: 79vh;
  overflow: scroll;
}

.graph-container .form-control,
.graph-container .card-body {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 8px 6px -6px #ddd;
  box-shadow: 0 8px 6px -6px #ddd;
}

/*- Back side -*/
.side-back {
  z-index: 2;
  -webkit-transform: rotateX(180deg);
  transform: rotateY(180deg);
  height: 85vh;
}

@media (max-width: 575px) {
  .side-back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

/* Activity */
.activity {
  min-height: 85vh;
}
.activity .list-group {
  max-height: 80%;
  overflow: auto;
}

.activity .list-group-item {
  background: white;
  margin: 8px 14px;
  border-radius: 5px;
  padding: 17px;
}

.activity .unassigned {
  border-left: solid 3px #fc9205;
}

.unassigned-heading {
  border-top: solid #fc9205;
}

.activity .assigned {
  border-left: solid 3px #0db3c7;
}

.assigned-heading {
  border-top: solid #0db3c7;
}

.activity .inTransit {
  border-left: solid 3px #982fb0;
}

.inTransit-heading {
  border-top: solid #982fb0;
}

.activity .complete {
  border-top: solid 3px #4da751;
}

.complete-heading {
  border-top: solid#4DA751;
}

.activity .failed {
  border-left: solid 3px #e8403d;
}

.failed-heading {
  border-top: solid #e8403d;
}

.activity h5 {
  padding: 7px 10px 10px 10px;
}

.empty-activity {
  height: 68px;
  padding: 18px 10px;
}

.calendar-container {
  overflow: auto;
  height: 80vh;
}

/* filter inputs */
.filter-inputs .MuiFormControl-root.MuiTextField-root {
  margin: 8px 0;
  width: 100%;
}

.filter-inputs .MuiButtonBase-root.MuiButton-root {
  margin: 5px 0;
}

.MuiSelect-select.MuiSelect-select {
  min-width: 82px;
}

.fade-in.container-fluid.main-container {
  background: #f9f9f9;
}

#filter-btn {
  min-width: 120px;
}

#orderTableNavbar .MuiTabs-root {
  width: 100%;
}

.filter-container .MuiFormControl-root {
  height: 41px;
  margin-right: 2px;
  margin-top: 1px;
  width: 230px;
}

.disabled {
  color: red !important;
}

.filter-container
  .MuiInputBase-root.MuiInput-root.searchCategory.MuiInputBase-formControl.MuiInput-formControl,
.filter-container
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense {
  height: 36px;
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 1px 2px 5px 0px rgba(221, 221, 221, 1);
  box-shadow: 1px 2px 5px 0px rgba(221, 221, 221, 1);
  padding: 0 10px;
  /* font-size: 14px; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 5px;
  color: #777;
}

.filter-container .MuiInput-underline:before {
  border-bottom: none;
}

.MuiBadge-colorPrimary {
  color: #fff;
  background-color: #0d8cd1 !important;
}

.EnhancedTable-root-8 {
  margin-top: 0;
}

.MuiAppBar-root a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:focus,
.MuiAppBar-root span.MuiTab-wrapper:focus,
.MuiAppBar-root a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:active,
.MuiAppBar-root span.MuiTab-wrapper:active,
.MuiAppBar-root a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:hover,
.MuiAppBar-root span.MuiTab-wrapper:hover {
  transition: 0.4s;
  text-decoration: none;
  opacity: 1;
}

.EnhancedTable-root-11 {
  margin-top: 0px !important;
}

.upload-container {
  max-height: 90vh;
  min-height: 81vh;
  width: 100%;
  overflow: auto;
}

#filters_body {
  width: 100%;
}

#filters_body .drawer-filter {
  width: 100px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: none;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #777777;
}

input#nav_elm::placeholder {
  color: #777;
  opacity: 1;
}

.rs-picker-toolbar-option {
  color: #0d8cd1 !important;
}

.rs-picker-toolbar-right-btn-ok {
  background-color: #0d8cd1 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  border-color: #0d8cd1 !important;
  background-color: #0d8cd1 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.rs-picker-toggle-placeholder {
  color: #777 !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.rs-picker-default .rs-picker-toggle {
  border: 1px solid rgb(221, 221, 221) !important;
}

.rs-picker-menu {
  z-index: 10 !important;
}

.filter-nav {
  display: flex;
  align-items: flex-start;
}

.makeStyles-toolbar-2 {
  z-index: 9999;
}

.fa-file-download {
  font-size: 19px;
}

.active-order-tabs {
  position: absolute;
  right: 0;
  box-shadow: 1px 2px 5px 0px rgba(221, 221, 221, 1);
}

.MuiTypography-h6 {
  font-size: 1rem !important;
}

.EnhancedTable-tableWrapper-40 {
  overflow: hidden !important;
}

.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
  padding: 4px 0px 4px 4px !important;
}

.blue-text {
  color: "#0d8cd1";
}
/* csv errors */
.csv-error-table {
  background: white;
  width: 99%;
  height: 92%;
  margin: 0 auto;
  padding: 14px;
  overflow: auto;
}
.csv-error-table th,
.csv-error-table td {
  border: solid 1px #ddd;
  padding: 5px;
}
.csv-error-fieldset {
  padding: 0px;
  width: fit-content;
  box-sizing: border-box;
}
.csv-error-input {
  border: none;
}
.csv-error {
  border: solid red 1px;
  color: red;
}
.csv-error-text {
  font-size: 10px;
  color: red;
  margin-bottom: -4px;
}

.ct-legend {
  position: relative;
  z-index: 10;
  display: flex;
}
.ct-legend li {
  position: relative;
  padding-left: 23px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}
.ct-legend li:before {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  content: "";
  border: 3px solid transparent;
  border-radius: 2px;
}
.ct-legend .inactive:before {
  background: transparent;
}
.ct-legend.ct-legend-inside {
  position: absolute;
  top: 0;
  right: 0;
}

.complete-percentage {
  background: #3fb259;
  border-radius: 3px;
  padding: 5px 15px;
  height: 32px;
  color: #fff;
}

.breakdown-report-filter {
  background: #ee5d59;
  border-radius: 3px;
  padding: 17px 15px;
  height: 46px;
}

input[type="file"] {
  display: none;
}

/* freshchat : position of the web messenger */

.custom_fc_frame {
  left: 20px !important;
  bottom: 30px !important;
}
#launcher-frame {
  bottom: 30px !important;
}
