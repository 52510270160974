.Logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.Logo-img {
  height: auto;
  width: auto;
  max-width: 17rem;
  max-height: 20rem;
}

@media only screen and (max-width: 576px) {
  .Logo-img {
    display: none;
  }
}

@media only screen and (max-width: 992px) and (max-height: 990px) {
  .Logo-img {
    display: none;
  }
}

.fade-in-2.container-fluid.mobile-margin {
  height: 93vh;
  background: #f7f7f7;
  /* overflow: auto; */
}

.Login {
  padding-top: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url('../../images/dubai-skyline.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Login .col-md-4 {
  background: #fff;
  border-radius: 10px;
  padding: 50px 30px;
  border: 1px solid #aeaeae;
  margin-bottom: 60px;
}
